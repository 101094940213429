import api from "./api";

const BASE_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// vytvoření renderu
// post vrací vzorový objekt Ok nebo Error
// zpravujeme a komponentě předáme je nezbytná data
const createRender = async (formData) => {
  let response;
  try {
    // axios při obdržení non 200 status code vyvolá výjimku
    response = await api.post("api/render/render", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Nastavení vlastního Content-Type potřebujme pro odeslání souboru, předefinujeme default který je application/json
      },
    });
    // pokud nedojde k výjimce tak je status 2xx
    // response.data obsahuje tělo z odpovědi serveru které je tvořeno util.getResponse jako { message: message, data: data }
    return {
      render_id: response.data.data.render_id,
      message: response.data.message,
    };
  } catch (error) {
    throw Error(error.response?.data.message ?? error.message);
    /*Co se stane při odpovědi se status kódem 400
    error.response.status bude 400.
    error.response.data bude obsahovat tělo odpovědi serveru
    výjimku pošleme dále */
  }
};

//vytvoření varianty renderu
const createVariation = async (render_id, room_type, style) => {
  let response;
  try {
    response = await api.post("api/render/variation", {
      render_id: render_id,
      room_type: room_type,
      style: style,
    });
    // pokud nedojde k výjimce tak je status 2xx
    // response.data obsahuje tělo z odpovědi serveru které je tvořeno util.getResponse jako { message: message, data: data }
    return {
      render_id: response.data.data.render_id,
      message: response.data.message,
    };
  } catch (error) {
    throw Error(error.response?.data.message ?? error.message);
    /*Co se stane při odpovědi se status kódem 400
     error.response.status bude 400.
     error.response.data bude obsahovat tělo odpovědi serveru
     výjimku pošleme dále */
  }
};

const syncRender = async (render_id) => {
  try {
    const result = await api.post("api/render/sync_render", { render_id: render_id });
    return result.data.data;
  } catch (error) {
    throw Error(error.response?.data.message ?? error.message);
  }
};

// vrací identifikátor render_paid - definuje předplatné které se bude čerpat
// vrátí hodnotu pokud existuje platné předplatné nebo null
// při non 2xx status code axios vyvolá výjimku
const getRenderPaid = async (user_id) => {
  try {
    const result = await api.get("api/render/render_paid/" + user_id);
    return result.data.data.render_paid_id;
  } catch (error) {
    return null;
  }
};

// vrací vytvořené rendery - pro jedno render_id
const getRenderImages = async (render_id) => {
  try {
    const result = await api.get("api/render/render_images/" + render_id);
    return {
      images: result.data.data.map((item) => ({
        original: item.local ? BASE_API_URL + item.original : item.original,
        description: item.description,
      })),
      message: "Ok",
    };
  } catch (error) {
    return { images: null, message: error.message };
  }
};

const RenderService = {
  createRender,
  createVariation,
  getRenderImages,
  getRenderPaid,
  syncRender,
};

export default RenderService;
