import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useUser } from "../contexts/user.provider";
import { toast } from "react-toastify";

export default function PriceCard({ item, canClick }) {
  const { user } = useUser();
  // volání url pro checkout provedu jako volání linku a nikoliv přes axios
  // na konci requestu dochází totiž k redirectu a při použití axiosu vzniká cors error
  // axios volá svoje requesty jako XMLHttpRequest který se liší od requestu zasílaného browserem
  const checkoutUrl = (priceId, clientId) => {
    const suffix = `/api/stripe/create-checkout-session?priceId=${priceId}&clientId=${clientId}`;
    return `${process.env.REACT_APP_BACKEND_API_URL}${suffix}`;
  };

  const handleOkClick = async () => {
    // kontrola existence platného emailu - načte se jako string
    if (user.email === "null") {
      toast.error("Pro nákup je nutné mít vyplněný email. Zadejte email v nastaveních profilu.");
    } else {
      window.location.href = checkoutUrl(item.id, user.id);
    }
  };

  return (
    <Card border='primary' bg='light' style={{ border: "1px solid" }}>
      <Card.Header as='h5' className='text-center'>
        {item.name}
      </Card.Header>
      <Card.Body>
        <Card.Text className='text-center'>{item.description}</Card.Text>
        <Card.Text className='text-center fw-bold'>
          {item.price} {item.currency}
        </Card.Text>
      </Card.Body>
      <div className='d-flex justify-content-center'>
        <Button variant='primary' disabled={!canClick} role='link' onClick={handleOkClick}>
          Zakoupit
        </Button>
      </div>
    </Card>
  );
}
