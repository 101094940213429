import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Body from "../components/body.component";
import { toast } from "react-toastify";

const StripeCancelRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    toast.error(`Během procesu platby došlo k chybě. Platba nebyla provedena.`);
    // přesměrovat na generator page
    let next = "/generator";
    if (location.state && location.state.next) {
      next = location.state.next;
    }
    navigate(next);
  }, [navigate, location]);

  return (
    <Body>
      <p>Platba byla stornována</p>
      <p>Probíhá přesměrování...</p>
    </Body>
  );
};

export default StripeCancelRedirect;
