import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { React, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import StripeService from "../services/stripe.service";
import { useUser } from "../contexts/user.provider";
import UserService from "../services/user.service";
import { CustomDateRenderer } from "../utils/date.utils";
import ModalDialog from "./modal.dialog.component";
import Spinner from "react-bootstrap/Spinner";
import { useQuery } from "@tanstack/react-query";
import ProfileHeaderGroup from "../components/header-group.component";

export default function ProfileSubscription() {
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [subsId, setSubsId] = useState(null);

  const handleCancelClose = () => {
    setShowModal(false);
    setSubsId(null);
  };

  const handleOkClose = async () => {
    setShowModal(false);
    if (subsId) {
      await StripeService.cancelSubscription(subsId);
    }
    setSubsId(null);
  };

  const CancelComponent = (props) => {
    if (props.data.status === "active") {
      return (
        <Button
          variant='danger'
          size='sm'
          onClick={() => {
            setShowModal(true);
            setSubsId(props.data.id);
          }}
        >
          Ukončit
        </Button>
      );
    } else {
      return null;
    }
  };

  const StatusComponent = (props) => {
    if (props.data.status === "active") {
      return <Badge bg='success'>{props.data.status}</Badge>;
    } else {
      return <Badge bg='secondary'>{props.data.status}</Badge>;
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: true,
      sortable: true,
    };
  }, []);

  const [colDefs] = useState([
    {
      headerName: "Seznam předplatného", // Společné záhlaví pro skupinu sloupců
      headerGroupComponent: ProfileHeaderGroup, // Vlastní komponenta pro renderování záhlaví
      children: [
        { field: "id", headerName: "ID", flex: 1 },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          cellRenderer: (p) => StatusComponent(p),
        },
        { field: "description", flex: 2, headerName: "Popis" },
        {
          field: "current_period_start",
          headerName: "Platnost od",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          field: "current_period_end",
          headerName: "Platnost do",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          field: "started_at",
          headerName: "Počátek",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          headerName: "Konec",
          flex: 1,
          valueGetter: (p) => p.data.canceled_at ?? p.data.ended_at,
          cellRenderer: CustomDateRenderer,
        },
        {
          headerName: "Ukončit",
          headerTooltip: "Vyvolá ukončení předplatného",
          sortable: false,
          flex: 1,
          filter: false,
          cellRenderer: (p) => CancelComponent(p),
        },
      ],
    },
  ]);

  // Funkce pro načtení dat s parametrem userId
  const fetchSubscriptionData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    const rows = await UserService.getUserSubscription(userId);
    return rows;
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["subscription", { userId: user.id }],
    queryFn: fetchSubscriptionData,
  });

  if (isPending) {
    return (
      <Container>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (isError) {
    return <Container></Container>;
  }

  return (
    <>
      <div
        className='ag-theme-quartz' // applying the grid theme
        style={{ width: "100%", height: "90%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={data}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          suppressMultiSort={true}
          tooltipShowDelay={500}
          paginationAutoPageSize={true} // Automaticky nastaví stránku podle výšky tabulky
          pagination={true}
        />
      </div>
      <ModalDialog
        title={"Předplatné"}
        body={"Opravdu si přejete zrušit předplatné ?"}
        cancelCaption={"Zpět"}
        okCaption={"Zrušit"}
        show={showModal}
        handleCancelClose={handleCancelClose}
        handleOkClose={handleOkClose}
      />
    </>
  );
}
