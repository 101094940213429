import { Stack } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Body from "../components/body.component";
import Container from "react-bootstrap/Container";
import GeneratorSidebar from "../components/generator-sidebar.component";
import { Carousel } from "react-responsive-carousel";
import RenderService from "../services/render.service";
import NologService from "../services/nolog.service";
import { useUser } from "../contexts/user.provider";
import { useQuery } from "@tanstack/react-query";
import { useState, useRef, useEffect } from "react";

export default function Generator() {
  const { user, userRender } = useUser();
  const [imageCnt, setImageCnt] = useState(0); // počet obrázků, při zvýšení počtu se nastavíme na poslední s nich
  const [refreshIndex, setRefreshIndex] = useState(0); // bezvýznamový index jehož změna vyvolá načtení seznamu obrázků
  const [imageHeight, setImageHeight] = useState(0);
  const containerRef = useRef(null); // Ref pro jiný element

  // carousel nastavuje svoji velikost podle velikosti image, pokud je image přílíš vysoký tak vytéká pod spodní kraj obrazovky
  // aby se tomu předešlo tak nastavím jeho maximální výšku podle nadřízeného div
  useEffect(() => {
    // Po načtení komponenty nastavíme velikost obrázku podle velikosti kontejneru
    if (containerRef.current) {
      const { offsetHeight } = containerRef.current;
      setImageHeight(offsetHeight * 0.9);
    }
  }, []);

  // Callback funkce, která bude volána podřízenou komponentou
  // vyvolá increment hodnoty
  const handleNeedRefresh = () => {
    setRefreshIndex((prevRefreshIndex) => prevRefreshIndex + 1);
  };

  // Funkce pro načtení dat
  // pokud není aktivní render tak načte specimen
  // proměnná refreshCnt která se předává do komponenty Generator jako parametr se změní vždy po generování
  // a její změna vyvolá opakované načtení query
  const fetchImagesData = async ({ queryKey }) => {
    const [_key, { render_id, i }] = queryKey; // eslint-disable-line no-unused-vars
    let imgs = { images: null, message: "" };
    if (render_id) {
      if (user?.id) {
        imgs = await RenderService.getRenderImages(render_id);
      }
    }
    if (imgs.images === null) {
      imgs = await NologService.getRenderSpecimen();
    }
    // posuneme carousel na poslední obrázek, pokud je větší než původní počet obrázků v seznamu
    // tj. pokud se načte nový obrázek na konec tak získá focus
    if (imgs.images) {
      if (imgs.images.length > imageCnt || imgs.images.length < imageCnt) {
        setActiveIndex(imgs.images.length - 1);
      }
      setImageCnt(imgs.images.length); // uložíme počet obrázků
    }
    return imgs.images;
  };

  const { isPending, data } = useQuery({
    queryKey: ["images", { render_id: userRender?.render_id, i: refreshIndex }],
    queryFn: fetchImagesData,
  });

  const [activeIndex, setActiveIndex] = useState(data ? data.length - 1 : 0); // uložíme si aktivní index v carouselu, výchozí bude poslední

  /*chci vytvořit dba slopuce na výšku celé obrazovky kde vlevo jsou select pro výběr parametrů a bottony ovládání
  a vpravo je carousel s vygenerovanými obrázky, pro vyšší obrázky by neměl carousel vytékat pod okraj obrazovky ale měl by
  se přizpůsobit tak aby se na obrazovku vešel celý*/
  return (
    <Body>
      <Stack direction='horizontal' style={{ height: "100%", alignItems: "flex-start" }}>
        <div /*className='col-3'*/ style={{ flexGrow: 3, height: "90%" }}>
          <GeneratorSidebar onNeedRefresh={handleNeedRefresh} />
        </div>
        <div /*className='col-9'*/ ref={containerRef} style={{ flexGrow: 9, height: "90%" }}>
          {isPending ? (
            <Spinner animation='border' />
          ) : (
            <Container style={{ height: "100%" }}>
              <Carousel
                showIndicators
                useKeyboardArrows
                thumbWidth={100}
                dynamicHeight={true}
                selectedItem={activeIndex} // Určuje, který obrázek bude aktivní
                onChange={(index) => setActiveIndex(index)} // Ukládá aktuálně aktivní obrázek
              >
                {data.map((item, index) => (
                  <div key={index}>
                    <img
                      src={item.original}
                      alt={item.description}
                      style={{ maxHeight: imageHeight, objectFit: "contain" }}
                    />
                    <p className='legend'>{item.description}</p>
                  </div>
                ))}
              </Carousel>
            </Container>
          )}
        </div>
      </Stack>
    </Body>
  );
}
