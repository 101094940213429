import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { React, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { useUser } from "../contexts/user.provider";
import UserService from "../services/user.service";
import { CustomDateRenderer } from "../utils/date.utils";
import Spinner from "react-bootstrap/Spinner";
import { useQuery } from "@tanstack/react-query";
import ProfileHeaderGroup from "../components/header-group.component";

export default function ProfileInvoice() {
  const { user } = useUser();

  const LinkPDFComponent = (props) => {
    return (
      <Button size='sm' onClick={() => (window.location.href = props.value)}>
        Link
      </Button>
    );
  };

  const StatusComponent = (props) => {
    if (props.data.status === "paid") {
      return <Badge bg='success'>{props.data.status}</Badge>;
    } else {
      return <Badge bg='danger'>{props.data.status}</Badge>;
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: true,
      sortable: true,
    };
  }, []);

  const [colDefs] = useState([
    {
      headerName: "Seznam faktur", // Společné záhlaví pro skupinu sloupců
      headerGroupComponent: ProfileHeaderGroup, // Vlastní komponenta pro renderování záhlaví
      children: [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "number", headerName: "Číslo", flex: 1 },
        {
          field: "received_at",
          headerName: "Vystaveno",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          cellRenderer: (p) => StatusComponent(p),
        },
        {
          headerName: "Celkem",
          flex: 1,
          valueGetter: (p) => p.data.total / 100 + " " + p.data.currency,
        },
        { field: "description", flex: 2, headerName: "Popis" },
        {
          field: "hosted_invoice_url",
          flex: 1,
          filter: false,
          sortable: false,
          headerName: "PDF",
          headerTooltip: "Link pro stažení PDF faktury",
          cellRenderer: LinkPDFComponent,
        },
      ],
    },
  ]);

  // Funkce pro načtení dat s parametrem userId
  const fetchInvoicesData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    const rows = await UserService.getUserInvoices(userId);
    return rows;
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["invoices", { userId: user.id }],
    queryFn: fetchInvoicesData,
  });

  if (isPending) {
    return (
      <Container>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (isError) {
    return <Container></Container>;
  }

  return (
    <div
      className='ag-theme-quartz' // applying the grid theme
      style={{ width: "100%", height: "90%" }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={data}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        tooltipShowDelay={500}
        suppressMultiSort={true}
        paginationAutoPageSize={true} // Automaticky nastaví stránku podle výšky tabulky
        pagination={true}
      />
    </div>
  );
}
