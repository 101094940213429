import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { React, useMemo, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import { useUser } from "../contexts/user.provider";
import UserService from "../services/user.service";
import { CustomDateRenderer, getTodayHistoryStatus } from "../utils/date.utils";
import Spinner from "react-bootstrap/Spinner";
import { useQuery } from "@tanstack/react-query";
import ProfileHeaderGroup from "../components/header-group.component";

export default function ProfileHistory() {
  const { user } = useUser();

  const StatusComponent = (props) => {
    const status = getTodayHistoryStatus(
      props.data.period_start,
      props.data.period_end,
      props.data.render_total_count,
      props.data.render_used_count
    );
    return <Badge bg={status.bg}>{status.status}</Badge>;
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: true,
      sortable: true,
    };
  }, []);

  /*const [rowData, setRowData] = useState([]);*/
  const [colDefs] = useState([
    {
      headerName: "Historie plateb a jejich čerpání", // Společné záhlaví pro skupinu sloupců
      headerGroupComponent: ProfileHeaderGroup, // Vlastní komponenta pro renderování záhlaví
      children: [
        { field: "invoice", headerName: "Faktura", flex: 1 },
        {
          headerName: "Status",
          flex: 1,
          cellRenderer: (p) => StatusComponent(p),
        },
        { field: "description", flex: 2, headerName: "Popis" },
        {
          field: "period_start",
          headerName: "Platnost od",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          field: "period_end",
          headerName: "Platnost do",
          flex: 1,
          cellRenderer: CustomDateRenderer,
        },
        {
          field: "render_total_count",
          headerName: "Zaplaceno ks",
          flex: 1,
        },
        {
          field: "render_used_count",
          headerName: "Vytvořeno ks",
          flex: 1,
        },
      ],
    },
  ]);

  // Funkce pro načtení dat s parametrem userId
  const fetchHistoryData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    const rows = await UserService.getUserHistory(userId);
    return rows;
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["history", { userId: user.id }],
    queryFn: fetchHistoryData,
    // https://tanstack.com/query/latest/docs/framework/react/guides/dependent-queries
    // The query will not execute until the user.id exists - toto není nutné protože strana je zobrazena pouze po přihlášení
    // enabled: !!user.id,
  });

  if (isPending) {
    return (
      <Container>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (isError) {
    return <Container></Container>;
  }

  return (
    <div
      className='ag-theme-quartz' // applying the grid theme
      style={{ width: "100%", height: "90%" }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={data}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        tooltipShowDelay={500}
        suppressMultiSort={true}
        paginationAutoPageSize={true} // Automaticky nastaví stránku podle výšky tabulky
        pagination={true}
      />
    </div>
  );
}
