import Container from "react-bootstrap/Container";

export default function HelpCreate({ children }) {
  return (
    <Container>
      <h3>Vytvoření vizualizace</h3>
      <p style={{ textAlign: "justify" }}>
        Na základě vybraného obrázku a vybraných parametrů se vyvolá vytváření první varianty vizualizace. Vytvoření
        vizualizace (resp. její první varianty) odčerpá jeden předplacený kredit. Zpracované podklady zůstanou uloženy v
        cloudu a mohou být využity pro vytvoření dalších variant. Vytvoření dalších variant předplacený kredit nečerpá.
      </p>
      <p style={{ textAlign: "justify" }}>
        Doba generování je ovlivněna velikostí zdrojového obrázku, požadavky na výstupní kvalitu obrázku a zatížením
        serveru.
      </p>
    </Container>
  );
}
