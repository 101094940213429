import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { React, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useUser } from "../contexts/user.provider";
import UserService from "../services/user.service";
import { CustomDateTimeRenderer } from "../utils/date.utils";
import Spinner from "react-bootstrap/Spinner";
import { useQuery } from "@tanstack/react-query";
import ProfileHeaderGroup from "../components/header-group.component";

export default function ProfileImage() {
  const { user } = useUser();

  const LinkPDFComponent = (props) => {
    return (
      <Button size='sm' onClick={() => (window.location.href = props.value)}>
        Link
      </Button>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: true,
      sortable: false,
    };
  }, []);

  /* použití valueGetter
    /* data vypadají takto
      tj. images nejsou vnořený objekt - je to pravděpodobně zapříčiněno inner joinem v query
    {
      id: "m8t3PCYWU9hd5qDj7LSH",
      mode: "fast",
      resolution: "4k",
      declutter: "off",
      "images.url": "https://storage.googleapis.com/furniture-ai.appspot.com/users/bIKkQSxd9BZttTBrKhgArH1JoGz2/renders/m8t3PCYWU9hd5qDj7LSH/outputs/0539aa11-cd8a-4594-adcc-2e7aa5db154e-u1_0.jpeg?GoogleAccessId=furniture-ai%40appspot.gserviceaccount.com&Expires=10366135438&Signature=SDQ0WhtxN%2Bml6CpiIccfBMaEws7L9PtIG45Pf3ygWZUSX7D2v988J3eshjvs2Eg%2BhZy5y5qic7QN7Lwg4slkkPIm2OtX4UR23MItKCptVwDO1oIMxMBpBuNXMEf%2B%2FvldWTKhD1tzGQYA%2BEOSW6%2BCUAQCrWn%2B49bK2ZEZmmWJ39BPbrj%2FWxpOrkB2yV3Q1WLzeVHlj8DxLSE5VR9y1fcfC2YnXRDdIg9O7EpDmKVS6zszYFq7xk0AXhefGkaQ5defFJPF7n0KJqd5ZrJY3IGNChwyCLdtJooPwcn4r53cUhniPsfLtUcEZSqlj8gohg2bOA%2FNT953GWMn3C74ebC3%2Fg%3D%3D",
      "images.name": "0539aa11-cd8a-4594-adcc-2e7aa5db154e-u1_0.jpeg",
      "images.room_type": "living",
      "images.style": "coastal",
      "images.render_at": "2024-09-11T12:02:47.000Z",
    }

    data potom získám takto (p) => p.data["images.render_at"],

    tj. toto je chyba (p) => p.data?.images?.render_at,
    pole s tečkou se interpretuje jako vnořený objekt, což ale fakticky není
  */

  const [colDefs] = useState([
    {
      headerName: "Seznam vytvořených variant vizualizací za posledních 7 dní", // Společné záhlaví pro skupinu sloupců
      headerGroupComponent: ProfileHeaderGroup, // Vlastní komponenta pro renderování záhlaví
      children: [
        {
          valueGetter: (p) => p.data["images.render_at"],
          headerName: "Vytvořeno",
          flex: 1,
          sort: "desc",
          cellRenderer: CustomDateTimeRenderer,
        },
        { field: "id", headerName: "ID", flex: 1, hide: true } /*sloupec skryjeme*/,
        {
          headerName: "Jméno souboru",
          flex: 2,
          valueGetter: (p) => p.data["images.name"],
        },
        {
          headerName: "Typ místnosti",
          valueGetter: (p) => p.data["images.room_type"],
          flex: 1,
        },
        {
          headerName: "Styl místnosti",
          valueGetter: (p) => p.data["images.style"],
          flex: 1,
        },
        { field: "mode", flex: 1, headerName: "Mód" },
        { field: "resolution", flex: 1, headerName: "Rozlišení" },
        {
          valueGetter: (p) => p.data["images.url"],
          flex: 1,
          filter: false,
          sortable: false,
          headerName: " Link",
          headerTooltip: " Link pro stažení souboru",
          cellRenderer: LinkPDFComponent,
        },
      ],
    },
  ]);

  // Funkce pro načtení dat s parametrem userId
  const fetchImagesData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    const rows = await UserService.getUserImages(userId);
    return rows;
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["images", { userId: user.id }],
    queryFn: fetchImagesData,
  });

  if (isPending) {
    return (
      <Container>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (isError) {
    return <Container></Container>;
  }

  return (
    <div
      className='ag-theme-quartz' // applying the grid theme
      style={{ width: "100%", height: "90%" }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={data}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        tooltipShowDelay={500}
        suppressMultiSort={true}
        paginationAutoPageSize={true} // Automaticky nastaví stránku podle výšky tabulky
        pagination={true}
      />
    </div>
  );
}
