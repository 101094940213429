import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Body from "../components/body.component";
import StripeService from "../services/stripe.service";
import { toast } from "react-toastify";

const StripeSuccessRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  //const [session, setSession] = useState({});

  useEffect(() => {
    (async () => {
      // page je volána po úspěšném checkoutu stripe, url obsahuje v query parametrech sessionID
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get("session_id");
      if (sessionId) {
        try {
          await StripeService.getSession(sessionId);
          //setSession(result);
          toast.success(
            `Zaplacení předplatného proběhlo úspěšně. Děkujeme. Informace o platbě naleznete v profilu uživatele`
          );
        } catch (e) {
          toast.error(`Chyba při zaplacení předplatného. ${e.message}`);
        }
        //console.log("Checkout session success - session_id : " + result.id);
      } //else setSession({});
      // přesměrovat na generator page
      let next = "/generator";
      if (location.state && location.state.next) {
        next = location.state.next;
      }
      navigate(next);
    })();
  }, [navigate, location]);

  // příklad zobrazení session objektu
  // <pre>{JSON.stringify(session, null, 2)}</pre>
  return (
    <Body>
      <p>Platba proběhla úspěšně</p>
      <p>Děkujeme za nákup !</p>
      <p>Probíhá přesměrování...</p>
    </Body>
  );
};

export default StripeSuccessRedirect;
