import Container from "react-bootstrap/Container";

export default function HelpDownload({ children }) {
  return (
    <Container>
      <h3>Stažení vytvořených souborů</h3>
      <p style={{ textAlign: "justify" }}>
        Všechny vytvořené vizualizace (zobrazené na stránce) se najednou uloží na disk počítače.
      </p>
      <p style={{ textAlign: "justify" }}>
        Některé webové prohlížeče při stažení většího množství souborů vyžadují potvrzení uživatele.
      </p>
      <p style={{ textAlign: "justify" }}>
        Vygenerované obrázky jsou fyzicky uloženy v cloudovém úložišti (nikoliv v prohlížeči nebo na disku počítače) a
        doba po kterou jsou dostupné je pouze několik dní po vytvoření. Z toho důvodu důrazně doporučujeme vytvořené
        soubory stáhnout do vlastního úložiště ihned po generování jinak hrozí že nebudou dostupné.
      </p>
    </Container>
  );
}
