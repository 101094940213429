import Container from "react-bootstrap/Container";

export default function HelpParams({ children }) {
  return (
    <Container>
      <h3>Nastavení parametrů</h3>
      <p style={{ textAlign: "justify" }}>
        Nastavením parametrů ovlivníte jakým způsobem se bude vizualizace generovat.
      </p>
      <p style={{ textAlign: "justify" }}>
        První vizualizace vytvořená z vybraného zdrojového obrázku slouží jako podklad pro vytváření dalších variant. Z
        toho důvodu je při vytváření varianty výběr parametrů pro generování omezen. Parametry které nelze změnit
        přeberou hodnotu podle prvního generování.
      </p>
    </Container>
  );
}
