import React from "react";
import { Stack, Button, Badge, Container, Form, Modal, Image } from "react-bootstrap";
import FormData from "form-data";
import Icon from "@mdi/react";
import { mdiBed, mdiSofa, mdiCamera, mdiTimerSandComplete, mdiAutoFix, mdiImage } from "@mdi/js";
import RenderingBtn from "../components/rendering-btn.component";
import HelpOpen from "../components/help-open.component";
import HelpParams from "../components/help-params.component";
import HelpCreate from "../components/help-create.component";
import HelpVariation from "../components/help-variation.component";
import HelpDownload from "../components/help-download.component";
import RenderService from "../services/render.service";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { useRef, useState, useEffect } from "react";
import { useUser } from "../contexts/user.provider";

const imageMimeType = /image\/(jpg|jpeg)/i;

export default function GeneratorSidebar({ onNeedRefresh }) {
  const roomTypeField = useRef();
  const styleField = useRef();
  const resolutionField = useRef();
  const modeField = useRef();
  const declutterField = useRef();
  const { user, userRender, setLastRender } = useUser();
  const [file, setFile] = useState(null); // vybraný soubor, výchozí hodnota je null
  const [fileDataURL, setFileDataURL] = useState(null); // url pro načtení preview
  const [isClicked, setIsClicked] = useState(false); // signalizuje že bylo kliknuto na vytvořit render nebo variantu
  const [showModal, setShowModal] = useState(false);
  const [currentHelp, setCurrentHelp] = useState(null);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef(null);

  function renderHelpContent() {
    if (currentHelp === 1) {
      return <HelpOpen />;
    } else if (currentHelp === 2) {
      return <HelpParams />;
    } else if (currentHelp === 3) {
      return <HelpCreate />;
    } else if (currentHelp === 4) {
      return <HelpVariation />;
    } else if (currentHelp === 5) {
      return <HelpDownload />;
    } else {
      return <></>;
    }
  }

  // Otevření modálu s nápovědou
  const handleHelpClick = (id) => {
    setCurrentHelp(id);
    setShowModal(true);
  };

  /* reakce na výběr souboru v input elementu*/
  const handleFileChange = (e) => {
    const fl = e.target.files[0];
    if (!fl.type.match(imageMimeType)) {
      toast.error(`Nevhodný typ souboru`);
      return;
    }
    setFile(fl);
  };

  /*https://blog.logrocket.com/using-filereader-api-preview-images-react/ */
  /* podpora pro zobrazení preview obrázku pomocí FileReader API*/
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  /*vytvoření nového renderu*/
  const handleCreateRender = async (ev) => {
    ev.preventDefault();
    // kontrolujeme zda byl vybrán soubor
    if (!file) {
      toast.error(`Vyberte výchozí soubor`);
      return;
    }
    if (file) {
      setIsClicked(true); // signalizujeme že bylo kliknuto
      let render_paid_id = null;
      // kontrolujeme zda existuje aktivní předplatné pokud jsem přihlášen
      if (user?.id) {
        render_paid_id = await RenderService.getRenderPaid(user?.id);
        if (!render_paid_id) {
          toast.error(
            `Źádné předplatné není aktivní. Vytváření vizualizací bude možné až po zakoupení jednorázového kreditu nebo předplatného`
          );
          setIsClicked(false);
          return;
        }
      }
      /*zobrazíme toast informující o rendrování, bez close buttonu*/
      toastId.current = toast("Čekejte prosím, probíhá zpracování", {
        type: "info",
        closeButton: false,
        autoClose: false,
      });
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("room_type", roomTypeField.current.value);
      formData.append("style", styleField.current.value);
      formData.append("mode", modeField.current.value);
      formData.append("resolution", resolutionField.current.value);
      formData.append("declutter", declutterField.current.value);
      formData.append("render_paid_id", render_paid_id);
      formData.append("image_file", file);
      try {
        let response = null;
        if (user?.id) {
          response = await RenderService.createRender(formData);
        }
        setLastRender(response.render_id); // uložíme id nového renderu
        /*pokud se nepovede vytvořit render taK je vyvolána výjimka*/
        onNeedRefresh(); // zavoláme callback, aby se obnovil seznam obrázků
        toast.update(toastId.current, {
          render: "Hotovo",
          type: "success",
          closeButton: true,
          autoClose: 1000,
          className: "rotateY animated",
        });
        setFile(null);
        //console.log("Create render done - render_id : " + response.render_id);
      } catch (error) {
        toast.update(toastId.current, {
          render: error.message,
          type: "error",
          closeButton: true,
          autoClose: 5000,
        });
        console.error(error);
      }
      setIsClicked(false);
    }
  };

  /*vytvoření varianty existujího renderu*/
  const handleCreateVariation = async (ev) => {
    ev.preventDefault();
    if (userRender?.render_id) {
      setIsClicked(true);
      try {
        /*zobrazíme toast informující o rendrování, bez close buttonu*/
        toastId.current = toast("Čekejte prosím, probíhá zpracování", {
          type: "info",
          closeButton: false,
          autoClose: false,
        });
        if (user?.id) {
          await RenderService.createVariation(
            userRender.render_id,
            roomTypeField.current.value,
            styleField.current.value
          );
        }
        onNeedRefresh(); // zavoláme callback, aby se obnovil seznam obrázků
        toast.update(toastId.current, {
          render: "Hotovo",
          type: "success",
          closeButton: true,
          autoClose: 1000,
          className: "rotateY animated",
        });
        //console.log("Create variation done - render_id : " + userRender.render_id);
      } catch (error) {
        toast.update(toastId.current, {
          render: error.message,
          type: "error",
          closeButton: true,
          autoClose: 5000,
        });
        console.error(error);
      }
      setIsClicked(false);
    }
  };

  /*načtení stavu renderu z externího API*/
  const handleSync = async (ev) => {
    ev.preventDefault();
    if (userRender?.render_id) {
      try {
        if (user?.id) {
          await RenderService.syncRender(userRender.render_id); // načte stav z externího api a uloží ho do databáze, vrátí stav renderu
        }
        onNeedRefresh(); // zavoláme callback, aby se obnovil seznam obrázků
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  /*vlastní stažení souborů, dynamicky vytvoří link element <a>, simuluje click a element zase smaže*/
  const downloadFile = async (url, fileName) => {
    try {
      let response = await fetch(url);
      let blob = await response.blob();
      const objUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = objUrl;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Chyba při načtení souboru: ", error);
    }
  };

  /*stažení vytvořených renderů
    načte images aktivního renderu
    stažení většího množství souborů může být blokováno browserem resp. může vyžadovat potvrzení uživatele
  */
  const handleDownload = async (ev) => {
    ev.preventDefault();
    if (userRender?.render_id) {
      let imgs = { images: null, message: "" };
      if (user?.id) {
        imgs = await RenderService.getRenderImages(userRender.render_id);
      }
      if (imgs.images) {
        imgs.images.forEach((img) => {
          downloadFile(img.original, img.description);
        });
        toast.success(`Bylo vyžádáno ${imgs.images.length} souborů ke stažení`);
      }
    }
  };

  return (
    <>
      <Form className='Sidebar'>
        <Stack direction='vertical' gap={4}>
          <Container>
            <Form.Group className='mb-3'>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiImage} size={1} />
                &nbsp;
                <h2>Vlož obrázek</h2>&nbsp;
                <Badge bg='info' pill id='hlpOpenFile'>
                  1
                </Badge>
              </Stack>
              <Form.Control type='file' accept='.jpg,.jpeg' onChange={handleFileChange} />
            </Form.Group>
            {fileDataURL ? (
              <Image src={fileDataURL} thumbnail alt='preview' style={{ maxWidth: "250px", maxHeight: "250px" }} />
            ) : null}
          </Container>

          <Stack direction='vertical' gap={3}>
            <Container>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiBed} size={1} />
                &nbsp;
                <h2>Typ pokoje</h2>&nbsp;
                <Badge bg='info' pill id='hlpSelectParams'>
                  2
                </Badge>
              </Stack>
              <Form.Select ref={roomTypeField} disabled={!(userRender?.render_id !== null || file !== null)}>
                <option value='living'>Obývací pokoj</option>
                <option value='bed'>Ložnice</option>
                <option value='kitchen'>Kuchyně</option>
                <option value='dining'>Jídelna</option>
                <option value='bathroom'>Koupelna</option>
                <option value='kids_room'>Dětský pokoj</option>
                <option value='home_office'>Kancelář</option>
                <option value='outdoor'>Exteriér</option>
              </Form.Select>
            </Container>
            <Container>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiSofa} size={1} />
                &nbsp;
                <h2>Styl pokoje</h2>
              </Stack>
              <Form.Select ref={styleField} disabled={!(userRender?.render_id !== null || file !== null)}>
                <option value='standard'>Standardní</option>
                <option value='modern'>Moderní</option>
                <option value='scandinavian'>Skandinávský</option>
                <option value='industrial'>Industriální</option>
                <option value='midcentury'>Midcentury</option>
                <option value='luxury'>Luxusní</option>
                <option value='farmhouse'>Farmářský</option>
                <option value='coastal'>Přímořský</option>
              </Form.Select>
            </Container>
            <Container>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiCamera} size={1} />
                &nbsp;
                <h2>Rozlišení</h2>
              </Stack>
              <Form.Select ref={resolutionField} disabled={file === null}>
                <option value='4k'>4k</option>
                <option value='full-hd'>Full-HD</option>
              </Form.Select>
            </Container>
            <Container>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiTimerSandComplete} size={1} />
                &nbsp;
                <h2>Kvalita</h2>
              </Stack>
              <Form.Select ref={modeField} disabled={file === null}>
                <option value='photorealism'>Fotorealistický</option>
                <option value='fast'>Rychlý</option>
              </Form.Select>
            </Container>
            <Container>
              <Stack direction='horizontal' className='IconHeader'>
                <Icon path={mdiAutoFix} size={1} />
                &nbsp;
                <h2>Odstranit nábytek</h2>
              </Stack>
              <Form.Select ref={declutterField} disabled={file === null}>
                <option value='off'>Ne</option>
                <option value='on'>Ano</option>
              </Form.Select>
            </Container>
          </Stack>
          {/* Vytvořit vizualizaci - povoleno pouze pokud je načtený soubor
            Vytvořit variantu - povoleno pouze pokud je aktivní render
            Synchronizovat - povoleno pouze pokud je aktivní render + v produkci natvrdo hidden
            Stáhnout  - povoleno pouze pokud je aktivní render
            signalizace rendering...
            1. bylo kliknuto a běží handler nebo
            2. uživatel má aktivní render a ten není done
        */}
          <Stack direction='vertical' gap={3}>
            <Container>
              <Stack direction='horizontal' gap={2}>
                {isClicked ? (
                  <RenderingBtn />
                ) : (
                  <Button
                    variant='primary'
                    className='SideButton'
                    onClick={handleCreateRender}
                    disabled={file === null}
                  >
                    Vytvořit vizualizaci
                  </Button>
                )}
                <Badge bg='info' pill id='hlpCreate'>
                  3
                </Badge>
              </Stack>
            </Container>
            <Container>
              <Stack direction='horizontal' gap={2}>
                {isClicked ? (
                  <RenderingBtn />
                ) : (
                  <Button
                    variant='primary'
                    className='SideButton'
                    onClick={handleCreateVariation}
                    disabled={!userRender?.render_id}
                  >
                    Vytvořit variantu
                  </Button>
                )}
                <Badge bg='info' pill id='hlpVariation'>
                  4
                </Badge>
              </Stack>
            </Container>
            <Container hidden={true}>
              <Stack direction='horizontal' gap={2}>
                {isClicked ? (
                  <RenderingBtn />
                ) : (
                  <Button
                    variant='primary'
                    className='SideButton'
                    onClick={handleSync}
                    disabled={!userRender?.render_id}
                  >
                    Synchronizovat
                  </Button>
                )}
                <Badge bg='info'>6</Badge>
              </Stack>
            </Container>
            <Container>
              <Stack direction='horizontal' gap={2}>
                {isClicked ? (
                  <RenderingBtn />
                ) : (
                  <Button
                    variant='primary'
                    className='SideButton'
                    onClick={handleDownload}
                    disabled={!userRender?.render_id}
                  >
                    Stáhnout vizualizace
                  </Button>
                )}
                <Badge bg='info' pill id='hlpDownload'>
                  5
                </Badge>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      </Form>
      {/* Tooltipy */}
      <Tooltip anchorSelect='#hlpOpenFile' variant='info' place='bottom-end' delayShow={100} clickable>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Vyberte zdrojový obrázek</p>
          <Button onClick={() => handleHelpClick(1)} variant='outline-light' size='sm'>
            Nápověda
          </Button>
        </div>
      </Tooltip>
      <Tooltip anchorSelect='#hlpSelectParams' variant='info' place='top-end' delayShow={100} clickable>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Vyberte parametry pro generování</p>
          <Button onClick={() => handleHelpClick(2)} variant='outline-light' size='sm'>
            Nápověda
          </Button>
        </div>
      </Tooltip>
      <Tooltip anchorSelect='#hlpCreate' variant='info' place='top-end' delayShow={100} clickable>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Vytvořte vizualizaci</p>
          <Button onClick={() => handleHelpClick(3)} variant='outline-light' size='sm'>
            Nápověda
          </Button>
        </div>
      </Tooltip>
      <Tooltip anchorSelect='#hlpVariation' variant='info' place='top-end' delayShow={100} clickable>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Vytvořte variantu vizualizace</p>
          <Button onClick={() => handleHelpClick(4)} variant='outline-light' size='sm'>
            Nápověda
          </Button>
        </div>
      </Tooltip>
      <Tooltip anchorSelect='#hlpDownload' variant='info' place='top-end' delayShow={100} clickable>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Stáhněte vytvořené soubory</p>
          <Button onClick={() => handleHelpClick(5)} variant='outline-light' size='sm'>
            Nápověda
          </Button>
        </div>
      </Tooltip>
      {/* Modal pro zobrazení popisu prvků */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nápověda</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderHelpContent()}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Zavřít
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
