import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProvider from "./contexts/user.provider";
import ApiProvider from "./contexts/api.provider";
import Header from "./components/header.component";
import Login from "./pages/login.page";
import LoginRedirect from "./pages/login-redirect.page";
import Register from "./pages/register.page";
import ResetPassword from "./pages/reset-password.page";
import ResetPasswordConfirm from "./pages/reset-password-confirm.page";
import Profile from "./pages/profile.page";
import Prices from "./pages/prices.page";
import Generator from "./pages/generator.page";
import PrivateRoute from "./components/private-route.component";
import Test from "./pages/test.page";
import StripeCancelRedirect from "./pages/stripe-cancel-redirect.page";
import StripeSuccessRedirect from "./pages/stripe-success-redirect.page";

export default function App() {
  return (
    <Container fluid className='App' style={{ height: "100vh" }}>
      {" "}
      {/* fluid zajišťuje šířku 100%, height: 100vh zajistí výšku */}
      <BrowserRouter>
        <ApiProvider>
          <UserProvider>
            <Header />
            <Routes>
              <Route path='/' element={<Generator />} />
              <Route path='/login' element={<Login />} />
              <Route path='/loginredirect' element={<LoginRedirect />} />
              <Route path='/stripecancelredirect' element={<StripeCancelRedirect />} />
              <Route path='/stripesuccessredirect' element={<StripeSuccessRedirect />} />
              <Route path='/register' element={<Register />} />
              <Route path='/resetpassword' element={<ResetPassword />} />
              <Route path='/resetpasswordconfirm' element={<ResetPasswordConfirm />} />
              <Route path='/profile' element={<PrivateRoute>{<Profile />}</PrivateRoute>} />
              <Route path='/generator' element={<Generator />} />
              <Route path='/prices' element={<Prices />} />
              <Route path='/test' element={<Test />} />
            </Routes>
          </UserProvider>
        </ApiProvider>
      </BrowserRouter>
      <ToastContainer position='bottom-center' theme='colored' draggable />
    </Container>
  );
}
