import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Body from "../components/body.component";
import Row from "react-bootstrap/Row";
import Gravatar from "../components/gravatar.component";
import * as formik from "formik";
import * as yup from "yup";
import AuthService from "../services/auth.service";
import { useUser } from "../contexts/user.provider";

export default function ResetPasswordConfirm() {
  const { Formik } = formik;
  const [userToReset, setuserToReset] = useState(false);
  const { setCurrentUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  // page je volána jako link pro obnovení hesla, url obsahuje v query parametrech token
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    (async () => {
      const result = await AuthService.validateResetPassword(token); // pokud nedostanu email tak je token nevalidní a přesměruji na login
      if (result?.email) {
        setuserToReset(result);
      } else {
        toast.error(`Platnost linku pro změnu hesla vypršela. Opakujte prosím reset hesla.`);
        let next = "/login";
        if (location.state && location.state.next) {
          next = location.state.next;
        }
        navigate(next);
      }
    })();
    // eslint-disable-next-line
  }, []); // provést pouze jednou po načtení komponenty

  const schema = yup.object().shape({
    password: yup
      .string("Zadejte heslo")
      .required("Vyplnění pole je povinné")
      .min(6, "Heslo musí mít délku alespoň 6 znaků"),
    confirmPassword: yup
      .string("Zadejte pro kontrolu heslo znovu")
      .required("Vyplnění pole je povinné")
      .oneOf([yup.ref("password"), null], "Zadaná hesla musí být stejná"),
  });

  return (
    <Body>
      <Card style={{ width: "60%" }}>
        <Container>
          <Row xs={2} md={4} lg={6} className='justify-content-md-center'>
            <Gravatar size={128} />
          </Row>
        </Container>
        <Card.Body>
          <Card.Title>Zapomenuté heslo</Card.Title>
          {!userToReset ? (
            <></>
          ) : (
            <Card.Subtitle className='mb-2'>Nastavte nové heslo pro emailovou adresu {userToReset.email}</Card.Subtitle>
          )}
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                // uložit nové heslo
                const saved = await AuthService.saveResetPassword(token, values.password, values.confirmPassword);
                // okamžitě přihlásíme
                const usr = await AuthService.login(saved.data.data.username, values.confirmPassword);
                // uložíme hodnoty do user providera a local storage
                setCurrentUser(usr.id, usr.username, usr.email, false, usr.sessionid);
                setSubmitting(false);
                toast.success(`Uživatel ${usr.username} je přihlášen`);
                setSubmitting(false);
                // přejdeme na generator page
                let next = "/generator";
                if (location.state && location.state.next) {
                  next = location.state.next;
                }
                navigate(next);
              } catch (error) {
                // vyvolá se pokud status code not 2xx
                setSubmitting(false);
                // pokud se vrátí response tak je chyba v error.response.data.message kam jsem si ji uložili
                // při obecné network error (např. backend neběží a tudíž custom chyba není vyvolána) je text chyby v error.message
                toast.error(error.response.data.message ?? error.message);
                // při chybě přejdeme na login page
                let next = "/login";
                if (location.state && location.state.next) {
                  next = location.state.next;
                }
                navigate(next);
              }
            }}
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
          >
            {(
              { handleSubmit, handleChange, values, touched, errors, isSubmitting } // eslint-disable-line no-unused-vars
            ) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId='password'>
                  <Form.Label>Heslo</Form.Label>
                  <Form.Control
                    type='text'
                    name='password'
                    autoComplete='new-password'
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='confirmPassword'>
                  <Form.Label>Heslo znovu pro kontrolu</Form.Label>
                  <Form.Control
                    type='text'
                    name='confirmPassword'
                    autoComplete='new-password'
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>
                <br />
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Nastavit heslo
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Body>
  );
}
