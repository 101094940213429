import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../services/user.service";
import { useQuery } from "@tanstack/react-query";

const UserContext = createContext();

export default function UserProvider({ children }) {
  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(getCurrentUser()); /*aktivní uživatel*/
  const [userRender, setUserRender] = useState(null); /*aktivní = poslední render aktivního uživatele*/

  useEffect(() => {
    (async () => {
      // načtu aktuálního uživatele z local storage
      setUser(getCurrentUser());
    })();
  }, [user?.id]);

  // provést při načtení aplikace - ověříme že uživatel existuje
  useEffect(() => {
    (async () => {
      // načtu aktuálního uživatele z local storage
      const usr = getCurrentUser();
      if (usr) {
        try {
          //pokouším se načíst uloženého usera, pokud není nalezen tak přesměruji na login
          //může se to stát hlavně při vývoji pokud měním databáze apod tak v local storage je user ale není v databázi, vyvolám login
          await UserService.getUser(usr.id);
        } catch {
          clearUserRender(); // smažeme data v local storage + providerovi
          // přesměrovat na generator page
          let next = "/login";
          if (location.state && location.state.next) {
            next = location.state.next;
          }
          navigate(next);
        }
      }
    })();
  }, []); // eslint-disable-line  react-hooks/exhaustive-deps

  // Funkce pro načtení posledního renderu s parametrem userId
  // pokud je user přihlášen načtu jeho rendery, pokud není přihlášen načtu poslední render nologa (podle ip)
  const fetchLastRenderData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    if (userId) {
      const rsd = await UserService.getUserLastRender(userId);
      // pokud jsem přihlášen načtu poslední render uživatele
      setLastRender(rsd?.last_render_id);
      return rsd;
    } else {
      setLastRender(null);
      return null;
    }
  };

  useQuery({
    queryKey: ["last_render", { userId: user?.id }],
    queryFn: fetchLastRenderData,
  });

  const refreshUser = () => {
    setUser(getCurrentUser());
    setUserRender(getLastRender());
  };

  const clearUserRender = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("render");
    setUser(null);
    setUserRender(null);
  };

  const setCurrentUser = (id, username, email, oauth, sessionid) => {
    // 1. aktualizujeme state varaible
    // nelze měnit přímo user - je nutno o něm uvažovat jako read only, nutno použít setter funkci
    setUser({
      id: id,
      username: username,
      email: email ?? "",
      oauth: oauth ?? false,
      sessionid: sessionid ?? "",
    });
    // 2. uložíme hodnoty do local storage
    localStorage.setItem(
      "user",
      JSON.stringify({
        id: id,
        username: username,
        email: email ?? "",
        oauth: oauth ?? false,
        sessionid: sessionid ?? "",
      })
    );
  };

  const getLastRender = () => {
    return JSON.parse(localStorage.getItem("render"));
  };

  const setLastRender = (render_id) => {
    //1. uložíme hodnoty do local storage
    localStorage.setItem(
      "render",
      JSON.stringify({
        render_id: render_id,
      })
    );
    // 2. aktualizujeme state variable
    // nelze měnit přímo render - je nutno o něm uvažovat jako read only a použít setter
    setUserRender({
      render_id: render_id,
    });
  };

  return (
    <UserContext.Provider value={{ user, userRender, setCurrentUser, setLastRender, clearUserRender, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
