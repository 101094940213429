import api from "./api";

const BASE_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// při neexistenci renderu vrací vzorové obrázky
// při chybě vrátí null
const getRenderSpecimen = async () => {
  try {
    // backend nevaliduje usera
    const result = await api.get("api/nolog/render_specimen");
    // do cesty ke statickým obrázkům doplníme base path aby je bylo možno zobrazit
    return {
      images: result.data.data.map((item) => ({
        original: item.local ? BASE_API_URL + item.original : item.original,
        description: item.description,
      })),
      message: "Ok",
    };
  } catch (error) {
    return { images: null, message: error.message };
  }
};

const NologService = {
  getRenderSpecimen,
};

export default NologService;
