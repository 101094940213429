import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Body from "../components/body.component";
import Row from "react-bootstrap/Row";
import Gravatar from "../components/gravatar.component";
import * as formik from "formik";
import * as yup from "yup";
import AuthService from "../services/auth.service";

export default function ResetPassword() {
  const { Formik } = formik;
  const navigate = useNavigate();
  const location = useLocation();

  const schema = yup.object().shape({
    email: yup.string("Enter your email").required("Vyplnění pole je povinné").email(),
  });

  return (
    <Body>
      <Card style={{ width: "60%" }}>
        <Container>
          <Row xs={2} md={4} lg={6} className='justify-content-md-center'>
            <Gravatar size={128} />
          </Row>
        </Container>
        <Card.Body>
          <Card.Title>Zapomenuté heslo</Card.Title>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                // registrujeme
                await AuthService.resetPassword(values.email);
                setSubmitting(false);
                toast.success(`Na email ${values.email} byl zaslán link pro obnovení hesla`);
                // přejdeme na login page
                let next = "/login";
                if (location.state && location.state.next) {
                  next = location.state.next;
                }
                navigate(next);
              } catch (error) {
                // vyvolá se pokud status code not 2xx
                setSubmitting(false);
                // pokud se vrátí response tak je chyba v error.response.data.message kam jsem si ji uložili
                // při obecné network error (např. backend neběží a tudíž custom chyba není vyvolána) je text chyby v error.message
                toast.error(error.response.data.message ?? error.message);
              }
            }}
            initialValues={{
              email: "",
            }}
          >
            {(
              { handleSubmit, handleChange, values, touched, errors, isSubmitting } // eslint-disable-line no-unused-vars
            ) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    name='email'
                    value={values.email}
                    autoComplete='email'
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <br />
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  Reset hesla
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Body>
  );
}
