import React from "react";
import Body from "../components/body.component";
import PriceCard from "../components/price-card.component";
import CardGroup from "react-bootstrap/CardGroup";
import Alert from "react-bootstrap/Alert";
import StripeService from "../services/stripe.service";
import { useUser } from "../contexts/user.provider";
import Spinner from "react-bootstrap/Spinner";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function Prices() {
  const { user } = useUser();

  // Funkce pro načtení dat
  const fetchPricesData = async () => {
    const pricesP = await StripeService.getPricesPayment();
    const pricesS = await StripeService.getPricesSubscription();
    return { pricesPayments: pricesP, pricesSubscription: pricesS };
  };

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["prices"],
    queryFn: fetchPricesData,
  });

  if (isPending) {
    return (
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );
  }

  if (isError) {
    toast.error(error.message);
    return <Alert>Chyba konfigurace. Ceny nejsou definovány !</Alert>;
  }

  return (
    <Body>
      <h1>Ceník jednorázových kreditů a předplatného</h1>
      <CardGroup className='gap-3'>
        {data.pricesPayments.map((item, index) => (
          <PriceCard key={item.id} item={item} canClick={user} />
        ))}
      </CardGroup>
      <CardGroup className='gap-3'>
        {data.pricesSubscription.map((item, index) => (
          <PriceCard key={item.id} item={item} canClick={user} />
        ))}
      </CardGroup>
      {user ? (
        <></>
      ) : (
        <Alert className='mt-3'>Předplatné nebo jednorázový kredit může zakoupit pouze přihlášený uživatel</Alert>
      )}
    </Body>
  );
}
