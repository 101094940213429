import Container from "react-bootstrap/Container";

export default function HelpOpen({ children }) {
  return (
    <Container>
      <h3>Výběr zdrojového souboru</h3>
      <p style={{ textAlign: "justify" }}>
        Vyberte z disku obrázek který bude sloužit jako podklad pro generování vizualizace.
      </p>
      <p style={{ textAlign: "justify" }}>
        Podporované formáty souboru jsou jpeg nebo png. Vytvořená vizualizace zachová stejný formát. Extrémně velké
        podkladové soubory (více jak 5 MB) prodlužují dobu generování nebo mohou být generátorem odmítnuty.
      </p>
    </Container>
  );
}
