import Container from "react-bootstrap/Container";

export default function HelpVariation({ children }) {
  return (
    <Container>
      <h3>Vytvoření varianty vizualizace</h3>
      <p style={{ textAlign: "justify" }}>Na základě vybraných parametrů se vyvolá vytváření varianty vizualizace. </p>
      <p style={{ textAlign: "justify" }}>
        Výchozí obrázek se nevybírá, je zpracována uložen při vytváření první varinty vizualizace. Při vytváření
        varianty je výběr parametrů omezen. Parametry které nelze změnit zůstanou nastaveny stajně jako při vytváření
        první varianty. Vytvoření dalších variant předplacený kredit nečerpá.
      </p>
      <p style={{ textAlign: "justify" }}>Maximální počet variant z jedné vizualizace je 20.</p>
      <p style={{ textAlign: "justify" }}>
        Doba generování je ovlivněna velikostí zdrojového obrázku, požadavky na výstupní kvalitu obrázku a zatížením
        serveru.
      </p>
    </Container>
  );
}
