import { Stack, Col, Row } from "react-bootstrap";
import Body from "../components/body.component";

const yellowStyle = {
  fontSize: "20px",
  backgroundColor: "yellow",
};

const redStyle = {
  fontSize: "20px",
  backgroundColor: "red",
};

export default function Test() {
  return (
    <Body>
      <Row>
        <Col className='col-3 col-lg-2' style={yellowStyle}>
          <h3>YELLOW</h3>
        </Col>
        <Col className='col-9 col-lg-10' style={redStyle}>
          <h3>RED</h3>
        </Col>
      </Row>
      <Row>
        <Stack direction='horizontal' gap={3}>
          <div className='p-2 col-4 col-lg-2' style={redStyle}>
            First item
          </div>
          <div className='p-2 col-4 col-lg-8' style={redStyle}>
            Second item
          </div>
          <div className='p-2 col-4 col-lg-2' style={redStyle}>
            Third item
          </div>
        </Stack>
      </Row>
    </Body>
  );
}
