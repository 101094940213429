import api from "./api";

const getPricesPayment = async () => {
  try {
    //pokud voláme a je již odhlášeno tak vrátí status 401 což mě ale nezajímá, stejně s tím nebudu nic dělat
    const res = await api.get("api/stripe/prices-payment");
    return res.data;
  } catch (error) {
    throw new Error("Chyba konfigurace. Ceny nejsou definovány !");
  }
};

const getPricesSubscription = async () => {
  try {
    //pokud voláme a je již odhlášeno tak vrátí status 401 což mě ale nezajímá, stejně s tím nebudu nic dělat
    const res = await api.get("api/stripe/prices-subscription");
    return res.data;
  } catch (error) {
    throw new Error("Chyba konfigurace. Ceny nejsou definovány !");
  }
};

const getSession = async (sessionId) => {
  try {
    const response = await api.post("api/stripe/checkout-session", { sessionId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await api.post("api/stripe/cancel-subscription", { subscriptionId });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

const StripeService = {
  getPricesPayment,
  getPricesSubscription,
  getSession,
  cancelSubscription,
};

export default StripeService;
