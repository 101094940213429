import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { React, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useUser } from "../contexts/user.provider";
import UserService from "../services/user.service";
import RenderService from "../services/render.service";
import { CustomDateTimeRenderer } from "../utils/date.utils";
import Spinner from "react-bootstrap/Spinner";
import ModalDialog from "./modal.dialog.component";
import { useQuery } from "@tanstack/react-query";
import ProfileHeaderGroup from "../components/header-group.component";

export default function ProfileRender() {
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [rndId, setRndId] = useState(null);

  const handleCancelClose = () => {
    setShowModal(false);
    setRndId(null);
  };

  const handleOkClose = async () => {
    setShowModal(false);
    if (rndId) {
      //await StripeService.cancelSubscription(subsId);
      await RenderService.syncRender(rndId); // načte stav z externího api a uloží ho do databáze, vrátí stav renderu
    }
    setRndId(null);
  };

  const SyncComponent = (props) => {
    return (
      <Button
        variant='danger'
        size='sm'
        onClick={() => {
          setShowModal(true);
          setRndId(props.data.id);
        }}
      >
        Sync
      </Button>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: true,
      sortable: false,
    };
  }, []);

  const [colDefs] = useState([
    {
      headerName: "Seznam vytvořených vizualizací za posledních 7 dní", // Společné záhlaví pro skupinu sloupců
      headerGroupComponent: ProfileHeaderGroup, // Vlastní komponenta pro renderování záhlaví
      children: [
        { field: "id", headerName: "ID", flex: 1 },
        {
          valueGetter: (p) => p.data["render_at"],
          headerName: "Vytvořeno",
          flex: 1,
          sort: "desc",
          cellRenderer: CustomDateTimeRenderer,
        },
        {
          headerName: "Typ místnosti",
          valueGetter: (p) => p.data["room_type"],
          flex: 1,
        },
        {
          headerName: "Styl místnosti",
          valueGetter: (p) => p.data["style"],
          flex: 1,
        },
        { field: "mode", flex: 1, headerName: "Mód" },
        { field: "resolution", flex: 1, headerName: "Rozlišení" },
        {
          headerName: "Synchronizace",
          headerTooltip: "Vyvolá opakované načtení vytvořených variant",
          sortable: false,
          flex: 1,
          filter: false,
          cellRenderer: (p) => SyncComponent(p),
        },
      ],
    },
  ]);

  // Funkce pro načtení dat s parametrem userId
  const fetchRendersData = async ({ queryKey }) => {
    const [_key, { userId }] = queryKey; // eslint-disable-line no-unused-vars
    const rows = await UserService.getUserRenders(userId);
    return rows;
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["renders", { userId: user.id }],
    queryFn: fetchRendersData,
  });

  if (isPending) {
    return (
      <Container>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (isError) {
    return <Container></Container>;
  }

  return (
    <>
      <div
        className='ag-theme-quartz' // applying the grid theme
        style={{ width: "100%", height: "90%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={data}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={500}
          suppressMultiSort={true}
          paginationAutoPageSize={true} // Automaticky nastaví stránku podle výšky tabulky
          pagination={true}
        />
      </div>
      <ModalDialog
        title={"Synchronizace"}
        body={"Opravdu si přejete načíst znovu vytvořené varianty která patří k vybrané vizualizaci ?"}
        cancelCaption={"Zpět"}
        okCaption={"Načíst"}
        show={showModal}
        handleCancelClose={handleCancelClose}
        handleOkClose={handleOkClose}
      />
    </>
  );
}
