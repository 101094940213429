import api from "./api";

/* seznam faktur, při použití tanstack query potřebujeme aby při chybě vyvolal výjimku*/
const getUserInvoices = async (userId) => {
  try {
    let response = await api.get("api/user/invoices/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

const getUser = async (userId) => {
  try {
    let response = await api.get("api/user/user/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/* seznam předplatných, při použití tanstack query potřebujeme aby při chybě vyvolal výjimku*/
const getUserSubscription = async (userId) => {
  try {
    let response = await api.get("api/user/subscription/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/*seznam image uživatele za 7 dní, při použití tanstack query potřebujeme aby při chybě vyvolal výjimku*/
const getUserImages = async (userId) => {
  try {
    let response = await api.get("api/user/image/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/*seznam renderů uživatele za 7 dní, při použití tanstack query potřebujeme aby při chybě vyvolal výjimku*/
const getUserRenders = async (userId) => {
  try {
    let response = await api.get("api/user/render/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/* historie plateb uživatele, při použití tanstack query potřebujeme aby při chybě vyvolal výjimku*/
const getUserHistory = async (userId) => {
  try {
    let response = await api.get("api/user/history/" + userId);
    return response.data;
  } catch (error) {
    // pokud se vrátí response tak je chyba v error.response.data.message kam jsem si ji uložili
    // při obecné network error (např. backend neběží a tudíž custom chyba není vyvolána) je text chyby v error.message
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/*detail profilu uživatele*/
const getUserProfile = async (userId) => {
  try {
    const response = await api.get("api/user/profile/" + userId);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

/* poslední render uživatele */
const getUserLastRender = async (userId) => {
  try {
    // načtu poslední render uživatele, pokud neexistuje tak to není chyba
    const response = await api.get("api/user/user_render/" + userId);
    return response.data;
  } catch (error) {
    return null;
  }
};

const changeEmail = (userId, email) => {
  return api.post("api/user/changeemail", {
    id: userId,
    email,
  });
};

const UserService = {
  getUserInvoices,
  getUserSubscription,
  getUserHistory,
  getUserRenders,
  getUserImages,
  getUserProfile,
  getUser,
  getUserLastRender,
  changeEmail,
};

export default UserService;
